@import "../variables";

%default-loading {
  & > div {
    height: 60px;
    background-color: rgba(0, 0, 0, .1);
    position: relative;
    border-radius: 10px;

    + div {
      margin-top: 15px;
    }

    &:after {
      border-radius: 10px;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      animation-duration: 3s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #fff;
      background-size: 100vw;
      background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    }
  }
}

.loading-div {

  &.chat {
    @extend %default-loading;
    height: 100%;
    width: 100%;

    div {
      height: 100%;
      width: 100%;
    }
  }


  &.chat-header-value {
    @extend %default-loading;

    div {
      height: 20px;
      width: 100%;
      border-radius: 4px;
      max-width: css-max(50vw, 300px);

      &:after {
        animation-duration: 10s;
      }
    }

    &.chat-table-value {
      @extend %default-loading;

      div {
        height: 20px;
        width: 100%;
        border-radius: 4px;
        max-width: css-max(50vw, 300px);

        &:after {
          animation-duration: 25s;
        }
      }
    }
  }
}
