@import "../../variables/colors";
@import "../../mixins/transition";


@keyframes animate {
  to {
    margin-bottom: 20px;
  }

}


#loader {
  position: relative;

  .loading-container {
    z-index: 10;
    margin: auto;
    width: $loader-text-width;
    height: $loader-text-height;
    text-align: center;
    position: absolute;
    left: calc(50% - (#{$loader-text-width} / 2));
    top: calc(50% - (#{$loader-text-height} / 2));
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;

    #loading {
      letter-spacing: .5em;
      font-size: .7em;
      color: $loader-text-color;
      font-weight: bold;
    }
  }


  .animate {
    animation: animate .4s linear forwards;
  }

  #container {
    width: $loader-container-size;
    height: $loader-container-size;
    margin: auto;
    position: absolute;
    left: calc(50% - (#{$loader-container-size} / 2));
    top: calc(50% - (#{$loader-container-size} / 2));
  }

  .arm {
    overflow: hidden;
    position: absolute;
    left: calc(50% - 3px);
    bottom: 50%;
    height: 50%;
    width: 6px;
    margin: auto;
    transform-origin: center bottom;
  }

  .arm-line {
    @include transition();
    position: absolute;
    left: 50%;
    height: 100%;
    width: 1px;
    background: transparent;
    opacity: 1;

    &.hidden {
      opacity: 0;
    }
  }

  .dot {
    z-index: 2;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: $loader-dot-color;
  }
}
