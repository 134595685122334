@use "sass:list";
@import "../variables";
@import "../mixins";

%button {
  @include transition();
  @include rfs($text-xs);
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-weight: 700;
  background-color: transparent;

  // disabled
  &:disabled {
    cursor: not-allowed !important;
  }

  &:hover {
    cursor: pointer;

    &:disabled {
      cursor: not-allowed !important;
    }
  }

  $main-disabled-color: list.nth($button-disabled-colors, 1);
  $off-disabled-color: list.nth($button-disabled-colors, 2);
  $text-hover-disabled-color: list.nth($button-disabled-colors, 3);

  @each $themeName, $colors in $button-colors {

    $main-color: list.nth($colors, 1);
    $off-color: list.nth($colors, 2);
    $text-hover-color: list.nth($colors, 3);
    $secondary-group-color: list.nth($colors, 4);

    // filled button
    &.#{$themeName} {
      text-align: center;
      background-color: $main-color;
      border-color: $main-color;
      color: $off-color;

      // disabled
      &:disabled {
        background-color: $main-disabled-color;
        border-color: $main-disabled-color;
        color: $off-disabled-color;
      }

      &:hover {
        cursor: pointer;
        background-color: $off-color;
        border-color: $main-color;
        color: $main-color;

        // disabled hover
        &:disabled {
          background-color: $main-disabled-color;
          border-color: $main-disabled-color;
          color: $off-disabled-color;
        }
      }

      // outlined button
      &.outlined {
        border-width: 1px;
        background-color: $off-color;
        color: $main-color;

        // disabled
        &:disabled {
          background-color: transparent;
          border-color: $off-disabled-color;
          color: $off-disabled-color;
        }

        &:hover {
          background-color: $main-color;
          color: $off-color;

          // disabled
          &:disabled {
            background-color: transparent;
            border-color: $off-disabled-color;
            color: $off-disabled-color;
          }
        }
      }

      // text button
      &.text {
        background-color: transparent;
        color: $main-color;
        border: none;

        // disabled
        &:disabled {
          background-color: transparent;
          color: $off-disabled-color;
        }

        &:hover {
          color: $text-hover-color;

          // disabled
          &:disabled {
            background-color: transparent;
            color: $off-disabled-color;
          }
        }
      }

      // grouped button
      &.grouped {
        $background-color: lighten($main-color, 55%);
        $secondary-background-color: lighten($secondary-group-color, 45%);

        text-align: center;
        background-color: $background-color;
        border-color: $background-color;
        color: $main-color;
        margin-bottom: 15px;
        margin-right: 15px;

        // disabled
        &:disabled {
          background-color: $main-disabled-color;
          border-color: $main-disabled-color;
          color: $off-disabled-color;
        }

        &:hover {
          background-color: $secondary-background-color;
          border-color: $secondary-background-color;
          color: $main-color;

          // disabled
          &:disabled {
            background-color: transparent;
            border-color: $off-disabled-color;
            color: $off-disabled-color;
          }
        }

        &.active {
          background-color: $secondary-background-color;
          border-color: $secondary-background-color;
          color: $secondary-group-color;
        }
      }
    }
  }
}
