@import "../../variables";


.error-page {
  text-align: center;
  background-color: $background-color;
  width: 100%;
  height: 100%;

  &.root {
    width: 100vw;
    height: 100vh;
  }

  svg {
    max-width: 70%;
    max-height: 50%;
  }

  .message-box {
    display: block;
    word-wrap: break-word;
    max-width: 450px;
    box-sizing: border-box;
    padding: 20px;
    color: $error-page-message-color;
    font-weight: 300;

    h1 {
      font-size: 60px;
      line-height: 46px;
      margin-bottom: 40px;
      letter-spacing: 4px;
      color: $error-page-title-color;
    }
  }

  @media (min-width: 576px) {
    svg {
      max-width: 70%;
      max-height: 50%;
    }
  }

  @media (min-width: 768px) {
    svg {
      max-width: 70%;
      max-height: 60%;
    }
  }

  @media (min-width: 992px) {
    svg {
      max-width: 70%;
      max-height: 70%;
    }
  }

  @media (min-width: 1200px) {
    svg {
      max-width: 70%;
      max-height: 80%;
    }
  }

  .action-link-wrap {
    margin-top: 40px;
  }

  #Polygon-1, #Polygon-2, #Polygon-3, #Polygon-4, #Polygon-4, #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
    stroke: $polygon1-color;
  }


  #Polygon-2 {
    animation-delay: .2s;
    stroke: $polygon2-color;
  }


  #Polygon-3 {
    animation-delay: .4s;
    stroke: $polygon3-color;
  }

  #Polygon-4 {
    animation-delay: .6s;
    stroke: $polygon4-color;
  }

  #Polygon-5 {
    animation-delay: .8s;
    stroke: $polygon5-color;
  }

  @keyframes float {
    100% {
      transform: translateY(20px);
    }
  }

  .animated-button {
    color: white;
    border-radius: 5px;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0 rgba(255, 255, 255, .5),
    7px 7px 20px 0 rgba(0, 0, 0, .1),
    4px 4px 5px 0 rgba(0, 0, 0, .1);
    outline: none;
  }

  .btn {
    position: relative;
    right: 20px;
    bottom: 20px;
    border: none;
    box-shadow: none;
    width: 180px;
    height: 60px;
    line-height: 42px;
    margin: 10px;

    &.focus, &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    span {
      padding: 10px;
      background: linear-gradient(12deg, $error-page-title-color 0%, $error-page-title-color 100%);
      display: block;
      position: absolute;
      width: 180px;
      height: 60px;
      box-shadow: inset 2px 2px 2px 0 $error-page-button-box-shadow-color,
      7px 7px 20px 0 rgba(0, 0, 0, .1),
      4px 4px 5px 0 rgba(0, 0, 0, .1);
      border-radius: 50px;
      text-align: center;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all .3s;
      transition: all .3s;

      &:nth-child(1) {
        opacity: 0;
        box-shadow: -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
        -webkit-transform: rotateX(90deg);
        -moz-transform: rotateX(90deg);
        transform: rotateX(90deg);
        -webkit-transform-origin: 50% 50% -20px;
        -moz-transform-origin: 50% 50% -20px;
        transform-origin: 50% 50% -20px;
      }

      &:nth-child(2) {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: 50% 50% -20px;
        -moz-transform-origin: 50% 50% -20px;
        transform-origin: 50% 50% -20px;
      }

    }

    &:hover {
      span {
        &:nth-child(1) {
          opacity: 1;
          color: white;
          box-shadow: inset 2px 2px 2px 0 rgba(255, 255, 255, .5),
          7px 7px 20px 0 rgba(0, 0, 0, .1),
          4px 4px 5px 0 rgba(0, 0, 0, .1);
          -webkit-transform: rotateX(0deg);
          -moz-transform: rotateX(0deg);
          transform: rotateX(0deg);
        }

        &:nth-child(2) {
          opacity: 0;
          box-shadow: inset 2px 2px 2px 0 rgba(255, 255, 255, .5),
          7px 7px 20px 0 rgba(0, 0, 0, .1),
          4px 4px 5px 0 rgba(0, 0, 0, .1);
          color: transparent;
          -webkit-transform: rotateX(-90deg);
          -moz-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
        }
      }
    }
  }


}

