// **************** PADDINGS ****************

.px-6 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.px-7 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-8 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.pl-6 {
  padding-left: 3.5rem !important;
}

.pl-7 {
  padding-left: 4rem !important;
}

.pl-8 {
  padding-left: 4.5rem !important;
}

.py-2-5 {
  padding-top: .8rem !important;
  padding-bottom: .8rem !important;
}

.px-4-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-3-1 {
  padding-left: 1.1rem !important;
  padding-right: 1.1rem !important;
}


// **************** MARGINS ****************


.ml-6 {
  margin-left: 3.5rem !important;
}

.ml-7 {
  margin-left: 4rem !important;
}

.ml-8 {
  margin-left: 4.5rem !important;
}

.mt--3 {
  margin-top: -1rem !important;
}

.mt--4 {
  margin-top: -1.5rem !important;
}

.no-margin {
  margin: 0 !important;
}

// **************** HEIGHTS ****************


.min-vh-50 {
  min-height: 50vh;
}

.min-vh-25 {
  min-height: 50vh;
}

.max-vh-50 {
  max-height: 50vh;
}


// **************** WIDTHS ****************

@media (min-width: 576px) {
  .h-sm-100 {
    height: 100% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }
}

@media (min-width: 1024px) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }


  .w-lg-100 {
    width: 100% !important;
  }
}


// **************** BORDERS ****************

.rounded-xl {
  border-radius: 0.5rem;
}

.rounded-xxl {
  border-radius: 0.7rem;
}

.rounded-xxxl {
  border-radius: 1rem;
}

// **************** Z-INDICES ****************

.z-index-1 {
  z-index: 1;
}

.z-index-100 {
  z-index: 100;
}
