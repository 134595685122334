@import "variables";
@import "overrides";
@import "mixins";
@import "sections";
@import "../fonts";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;

  &:focus, &:active {
    outline: none !important;
  }
}

a {
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

p {
  margin-bottom: 0;
}

img {
  &.sharp {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
}

body {
  margin: 0;
  background-color: $background-color;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.transition {
  @include transition(0.3s, all);
}

.icon {
  width: 1em;
  height: 1em;

  &.xs {
    width: 0.7em;
    height: 0.7em;
  }

  &.sm {
    width: 0.8em;
    height: 0.8em;
  }

  &.lg {
    width: 1.2em;
    height: 1.2em;
  }

  &.xl {
    width: 1.5em;
    height: 1.5em;
  }
}

.panel-loading-overlay {
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: fixed;
  z-index: 1300;
  inset: 0;
  opacity: 0;

  .overlay {
    transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: -1;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: white;
    -webkit-tap-highlight-color: transparent;
  }

  &.show {
    opacity: 1;

    .overlay {
      opacity: 1;
    }
  }
}

.icon-button {
  background-color: $secondary-color-light !important;

  svg {
    fill: $secondary-color;
  }

  &:hover {
    color: $primary-color;

    svg {
      fill: $primary-color;
    }
  }

  &:disabled {
    background-color: $grey-light !important;

    svg {
      fill: $grey;
    }

    &:hover {
      color: $primary-color;

      svg {
        fill: $primary-color;
      }
    }
  }

  &.chat-bubble-icon {
    background-color: transparent !important;

    &.fill {
      svg {
        fill: $grey-light;
      }

      &:hover {
        background-color: $primary-color !important;

        svg {
          fill: white;
        }
      }
    }
  }

}

.switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  input {
    display: none;

    &:checked + i {
      background-color: $primary-color;

      &::before {
        transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
      }

      &::after {
        transform: translate3d(22px, 2px, 0);
      }
    }
  }

  &:active {
    input:checked + i::after {
      transform: translate3d(16px, 2px, 0);
    }

    i {
      &::after {
        width: 28px;
        transform: translate3d(2px, 2px, 0);
      }
    }
  }

  i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 42px;
      height: 22px;
      background-color: $white;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: $white;
      border-radius: 11px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
      transform: translate3d(2px, 2px, 0);
      transition: all 0.2s ease-in-out;
    }
  }
}

.settings {
  position: absolute;
  right: 20px;
  top: 20px;

  .icon-button {
    background-color: white !important;
    border: 2px solid $secondary-color;

    .abbr {
      border-radius: 100%;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      @include rfs($text-sm);
      color: $secondary-color;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.settings-popover {
  max-width: css-min(100vw, 300px) !important;
  padding: 20px 5px;

  .title {
    @include rfs($text-md);
    font-weight: 600;
    color: $secondary-color;
  }

  .entry {
    @include rfs($text-sm);
    font-weight: 500;
    color: $primary-text-color;
  }


}


.drop-zone {
  @include transition(0.2s);


  &.chat-drop-zone {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    border-radius: 10px 10px 0 10px;
    border: 2px dashed transparent;
    position: relative;


    &:hover {
      cursor: default !important;
    }

    &.high-light {
      border: 2px dashed $secondary-color;

      &:after {
        position: absolute;
        content: "Drop your images here";
        @include rfs($text-xs);
        color: $hint-text-color;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 100%;
      }
    }

    .chat-upload-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      label {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.chat-layout {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  padding: 10px;

  .inner-layout {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    background-color: $secondary-color-light;

    .chat-paper {
      background: white;
      border-radius: 10px;
      padding: 15px;

      .logo {
        max-width: 350px;
      }

      .chat-title {
        @include rfs($text-xl - 1);
        font-weight: 700;
      }

      .vendor-info {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .title {
          margin-left: 4px;
          @include rfs($text-sm);
          font-weight: 500;
        }

        .value {
          margin: 0 10px;
          @include rfs($text-sm);
          font-weight: 600;
          color: black;
        }
      }

      &.chat-table {
        display: flex;
        flex-direction: column;

        .chat-table-header, .chat-table-row {
          > div {
            display: flex;
            align-content: center;
            justify-content: center;
            text-align: center;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .chat-table-header {
          margin: 5px 15px 25px;

          p {
            color: darken($hint-text-color, 25%);
            @include rfs($text-sm - 1px);
            font-weight: 600;
            text-align: center;
          }
        }
      }

      .chat-table-divider {
        width: 100%;
        height: 0;
        box-shadow: 0 0 0 0.5px $grey-light;
      }

      .chat-table-row {
        margin: 15px 15px 5px;

        p {
          color: $secondary-text-color;
          @include rfs($text-sm - 1px);
          font-weight: 600;
        }

        .part-button {
          cursor: pointer;

          p {
            @include transition();
            color: $primary-color;
          }

          svg {
            @include transition();
            stroke: $primary-color;
          }

          &:hover {
            p {
              color: $primary-color-dark;
            }

            svg {
              stroke: $primary-color-dark;
            }
          }
        }
      }

      &.main-section {
        padding: 0;
      }

      #chat-section {
        overflow-y: scroll;
        object-fit: contain;
        height: 100%;
        width: 100%;
        padding: 10px 10px;

        img {
          border-radius: 10px;

          &.portrait {
            width: auto;
            max-height: 40vh;
          }

          &.landscape {
            width: 100%;
            height: auto;
          }

        }

        .chat-bubble {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin-bottom: 0.3rem;
          justify-content: flex-start;
          cursor: default;

          &.time {
            align-items: center;
            justify-content: center;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            color: darken($hint-text-color, 10%);
          }

          &.currentUser {
            justify-content: flex-end;
          }

          .bubble {
            border-radius: 1rem;
            padding: 1rem;
            background-color: $grey-light;

            &.currentUser {
              background-color: $secondary-color;
            }

            &.pending {
              cursor: progress;
            }

            &.error {
              cursor: pointer;
            }

            img {
              border-radius: 10px;

              &.portrait {
                width: auto;
                max-height: 40vh;
              }

              &.landscape {
                width: 100%;
                height: auto;
              }
            }

            &.file {
              border-radius: 15px;

              &:hover {
                cursor: pointer !important;
              }

              .file-icon {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }


    .chat-footer-divider {
      width: 100%;
      height: 0;
      box-shadow: 0 0 0 0.5px $grey-light;
    }

    .chat-footer {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px 15px 15px;

      .chat-input {
        width: 100%;
        background-color: $secondary-color-light;
        border: none;
        margin: 0 5px;
        border-radius: 25px;
        padding: 10px 12px;
        @include rfs($text-sm - 1px);
        font-weight: 400;
      }
    }
  }
}

.dialog {
  .dialog-paper {
    margin: 4px !important;
    border-radius: 5px !important;
    display: flex;
    flex-direction: column;
  }

  .title {
    h2 {
      @include rfs($text-lg);
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .slide-dialog {
    &.left {
      .MuiDialog-container {
        position: absolute;
        right: -32px;
        height: 100%;
      }
    }

    &.right {
      .MuiDialog-container {
        position: absolute;
        left: -32px;
        height: 100%;
      }
    }
  }

  .dialog-paper-container {
    position: relative;
    padding: 12px;

    .dialog-close-container {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 101;

      .close-icon {
        .bg {
          @include transition();
          fill: $secondary-color;
          fill-opacity: 0.7;
        }

        .icon {
          @include transition();
          fill: $white;
        }

        &:hover {
          cursor: pointer;

          .bg {
            fill-opacity: 1;
          }
        }
      }
    }

    .dialog-paper {
      @include transition();
      border-radius: 5px !important;
      box-shadow: -1px 2px 5px rgba(0, 0, 0, 0.25) !important;
      min-height: 1px;
    }
  }
}

.button {
  @extend %button;
}

.react-pdf__Document {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transition(0.2s);
  border-radius: 8px;
  max-width: 100%;
  //position: relative;
  background-color: white;

  .react-pdf__Page__annotations {
    width: 0;
    height: 0;

    .linkAnnotation {
      width: 0 !important;
      height: 0 !important;
    }
  }

  .react-pdf__Page {
    border-radius: 10px;
    height: 100%;
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    .page-controls {
      opacity: 1;
    }
  }

  .page-controls {
    position: absolute;
    bottom: -10%;
    left: 50%;
    background: white;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    border-radius: 4px;

    span {
      font: inherit;
      font-size: 1em;
      padding: 0 1em;
    }
  }
}


.file-preview {
  position: relative;

  .page-controls {
    bottom: 1%;
  }

  video {
    max-width: 100%;
    max-height: 74vh;
    border-radius: 15px;
    padding: 10px;
  }
}




