@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin app-font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;
  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src: $src;
  }
}

@include app-font-face('Montserrat', "./Montserrat-Thin", 100, normal, ttf);
@include app-font-face('Montserrat', "./Montserrat-ThinItalic", 100, italic, ttf);
@include app-font-face('Montserrat', "./Montserrat-ExtraLight", 200, normal, ttf);
@include app-font-face('Montserrat', "./Montserrat-ExtraLightItalic", 200, italic, ttf);
@include app-font-face('Montserrat', "./Montserrat-Light", 300, normal, ttf);
@include app-font-face('Montserrat', "./Montserrat-LightItalic", 300, italic, ttf);
@include app-font-face('Montserrat', "./Montserrat-Regular", 400, normal, ttf);
@include app-font-face('Montserrat', "./Montserrat-Italic", 400, italic, ttf);
@include app-font-face('Montserrat', "./Montserrat-Medium", 500, normal, ttf);
@include app-font-face('Montserrat', "./Montserrat-MediumItalic", 500, italic, ttf);
@include app-font-face('Montserrat', "./Montserrat-SemiBold", 600, normal, ttf);
@include app-font-face('Montserrat', "./Montserrat-SemiBoldItalic", 600, italic, ttf);
@include app-font-face('Montserrat', "./Montserrat-Bold", 700, normal, ttf);
@include app-font-face('Montserrat', "./Montserrat-BoldItalic", 700, italic, ttf);
@include app-font-face('Montserrat', "./Montserrat-ExtraBold", 800, normal, ttf);
@include app-font-face('Montserrat', "./Montserrat-ExtraBoldItalic", 800, italic, ttf);
@include app-font-face('Montserrat', "./Montserrat-Black", 900, normal, ttf);
@include app-font-face('Montserrat', "./Montserrat-BlackItalic", 900, italic, ttf);
