// **************** CURSORS ****************

.cursor-pointer-hover {
  &:hover {
    cursor: pointer !important;
  }
}

.cursor-default-hover {
  &:hover {
    cursor: default !important;
  }
}

.cursor-not-allowed-hover {
  &:hover {
    cursor: not-allowed !important;
  }
}

.cursor-progress-hover {
  &:hover {
    cursor: progress !important;
  }
}

.cursor-move-hover {
  &:hover {
    cursor: move !important;
  }
}

