@import "../variables";

@each $themeName, $colors in $themes {
  @each $name, $color in $colors {
    .#{$name} {
      color: #{$color} !important;
    }
    .bg-#{$name} {
      background-color: #{$color} !important;
    }
    .stroke-#{$name} {
      stroke: #{$color} !important;
    }
    .fill-#{$name} {
      fill: #{$color} !important;
    }
  }
}


// **************** HELPER COLORS ****************

.transparent {
  color: transparent !important;
}

.white {
  color: $white;
}

.black {
  color: black;
}
