@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1
  }
}

@keyframes zoomOut {
  from {
    opacity: 1
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0
  }
}

.zoom-in-animation {
  animation: zoomIn 3s ease-in-out 0s;
}

.zoom-out-animation {
  animation: zoomOut 3s ease-in-out 0s;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


@keyframes appear {
  0%, 50%, 90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100em 0;
  }
}
