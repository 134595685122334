@import "../variables";
@import "../mixins/transition";
@import "../mixins/rf";

.pagination {
  @include app-responsive-font-size(14, true);
  @include transition();

  .page-item {
    @include transition();
    font-weight: 600;
    margin: 0 6px;

    &:hover, &:focus {
      border: none;
      outline: none;

      .page-link {
        background-color: $page-link-bg-hover-color;
      }
    }

    .page-link {
      @include transition();
      border-radius: 7px;
      border: none !important;
      color: $page-link-color !important;
      cursor: pointer;
      box-shadow: none !important;
      background-color: transparent;
    }

    &.active {
      @include transition();

      .page-link {
        background-color: $page-link-bg-color !important;
        color: $white !important;
        border-color: transparent !important;
        border-radius: 7px;

        &:hover {
          background-color: $page-link-bg-hover-color !important;
          color: $white !important;
        }

        &:focus {
          background-color: $page-link-bg-focus-color !important;
          color: white !important;
          border: none;
          box-shadow: none !important;
        }
      }
    }
  }

  .prev, .next {
    border: none;
    margin: 0 10px;
    padding-bottom: 5px;
    cursor: pointer;
    @include transition();

    .page-link {
      @include transition();
      padding-bottom: 10px !important;
      border-radius: 5px !important;
      border: none !important;
      box-shadow: none;
    }

    &.disabled {
      cursor: not-allowed;

      .page-link {
        background-color: $page-link-bg-disabled-color !important;
        color: $page-link-disabled-color !important;

        &:focus, &:hover {
          background-color: $page-link-bg-disabled-color !important;
          color: $page-link-disabled-color !important;

          .MuiSvgIcon-root {
            color: $page-link-disabled-color !important;
          }
        }
      }
    }

    .page-link {
      background-color: $page-link-bg-hover-color;
      color: $page-link-color;

      &:focus, &:hover {
        background-color: $page-link-bg-focus-color !important;
        color: $page-link-hover-color !important;
      }
    }
  }

  .break-point {
    > a {
      color: $page-link-color;

    }
  }
}

