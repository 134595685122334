@import "../variables";

@mixin transition($transition: $default-transition-duration,$type: all,$easing: ease-in-out, $important: false) {
  @if ($important) {
    -webkit-transition: $type $transition $easing !important;
    -moz-transition: $type $transition $easing !important;
    -o-transition: $type $transition $easing !important;
    transition: $type $transition $easing !important;
  } @else {
    -webkit-transition: $type $transition $easing;
    -moz-transition: $type $transition $easing;
    -o-transition: $type $transition $easing;
    transition: $type $transition $easing;
  }
}


@mixin transition-delay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}


@mixin transition-duration($duration) {
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
}
