// FADE

.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 0.8s;
}


// CSS ITEM

.css-item-enter {
  opacity: 0;
}

.css-item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.css-item-exit {
  opacity: 1;
}

.css-item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
