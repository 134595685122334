@import "../overrides/css-functions";

// **************** APPLICATION TEXT SIZES ****************

$text-xxs: 10px;
$text-xs: 12px;
$text-sm: 14px;
$text-md: 16px;
$text-lg: 18px;
$text-xl: 20px;
$text-title: 26px;

// **************** LOADER SIZES ****************

$loader-container-size: 250px;
$loader-text-width: 100px;
$loader-text-height: 100px;
