// **************** APPLICATION COLORS ****************

$background-color: white;

$primary-color-dark: rgba(169, 58, 0, 1);
$primary-color: rgb(246, 93, 49);
$primary-color-light: rgba(229, 212, 194, 1);
$primary-color-lighter: rgba(250, 247, 245, 1);

$secondary-color-dark: rgba(131, 153, 174, 0.2);
$secondary-color: rgba(62, 110, 156, 1);
$secondary-color-light: rgba(230, 235, 239, 0.4);
$secondary-color-lighter: rgba(236, 239, 242, 1);

$white: white;
$black: black;

$green: rgba(45, 155, 109, 1);
$green-light: rgb(187, 250, 219);

$red: #f44336;
$red-light: #ef9a9a;

$default-shadow-color: #4F4F4F;

$grey: rgba(180, 174, 173, 1);
$grey-light: rgba(242, 242, 242, 1);

$primary-text-color: rgba(51, 51, 51, 1);
$secondary-text-color: rgba(79, 79, 79, 1);
$disabled-text-color: rgba(189, 189, 189, 0.5);
$hint-text-color: rgba(189, 189, 189, 1);


// **************** COLOR THEMES ****************

$primary-theme-color: ('primary-color': $primary-color, 'primary-color-dark': $primary-color-dark, 'primary-color-light': $primary-color-light, 'primary-color-lighter': $primary-color-lighter);
$secondary-theme-color: ('secondary-color': $secondary-color, 'secondary-color-dark': $secondary-color-dark, 'secondary-color-light': $secondary-color-light, 'secondary-color-lighter': $secondary-color-lighter);
$green-theme-color: ('green': $green, 'green-light': $green-light);
$red-theme-color: ('red': $red, 'red-light': $red-light);
$text-theme-color: ('primary-text-color': $primary-text-color, 'secondary-text-color': $secondary-text-color, 'disabled-text-color': $disabled-text-color, 'hint-text-color': $hint-text-color);

$themes: (
        'primary-theme-color': $primary-theme-color,
        'secondary-theme-color': $secondary-theme-color,
        'green-theme-color': $green-theme-color,
        'red-theme-color': $red-theme-color,
        'text-theme-color': $text-theme-color
);


// **************** PAGINATION OVERRIDE COLORS ****************

$page-link-bg-hover-color: $primary-color-light;
$page-link-bg-focus-color: $primary-color-dark;
$page-link-bg-color: $primary-color;
$page-link-color: $white;
$page-link-hover-color: $white;
$page-link-bg-disabled-color: $hint-text-color;
$page-link-disabled-color: $secondary-text-color;


// **************** MATERIAL UI OVERRIDE COLORS ****************

$mui-icon-button-disabled-bg-color: $grey-light;
$mui-icon-button-disabled-color: $grey;
$mui-checkbox-unchecked-color: $primary-color-lighter;
$mui-checkbox-checked-color: $primary-color;
$mui-radio-unchecked-color: $secondary-color;
$mui-radio-checked-color: $grey;

// **************** ERROR PAGE OVERRIDE COLORS ****************

$error-page-title-color: $primary-color;
$error-page-message-color: $primary-text-color;
$error-page-button-box-shadow-color: $primary-color-lighter;

$polygon1-color: $primary-color-light;
$polygon2-color: $primary-color-dark;
$polygon3-color: $primary-color;
$polygon4-color: $secondary-color-dark;
$polygon5-color: $secondary-color;

// **************** LOADER COLORS ****************

$loader-dot-color: $primary-color;
$loader-text-color: $secondary-color;


// **************** BUTTON COLOR THEMES ****************

$button-primary-colors: (
        $primary-color, // main / text color
        $white, // alternate color
        $white, // text hover color
        $primary-color // text active, and background active and hover
);

$button-secondary-colors: (
        $secondary-color, // main / text color
        $white, // alternate color
        $white, // text hover color
        $secondary-color // text active, and background active and hover
);

$button-disabled-colors: (
        $grey-light, // main / text color
        lighten($secondary-text-color, 40%), // alternate color
        lighten($secondary-text-color, 40%), // text hover color
);

$button-colors: (
        'primary': $button-primary-colors,
        'secondary': $button-secondary-colors,
);
