@import "../mixins/rf";
@import "../variables";

// **************** TEXT SIZES ****************

.text-xxs {
  @include app-responsive-font-size($text-xxs, true);
}

.text-xs {
  @include app-responsive-font-size($text-xs, true);
}

.text-sm {
  @include app-responsive-font-size($text-sm, true);
}

.text-md {
  @include app-responsive-font-size($text-md, true);
}

.text-lg {
  @include app-responsive-font-size($text-lg, true);
}

.text-xl {
  @include app-responsive-font-size($text-xl, true);
}

.text-title {
  @include app-responsive-font-size($text-title, true);
}

// **************** LETTER SPACING ****************


.letter-space-xs {
  letter-spacing: 0.5px !important;
}

.letter-space-sm {
  letter-spacing: 0.7px !important;
}

.letter-space-md {
  letter-spacing: 1px !important;
}

.letter-space-lg {
  letter-spacing: 1.3px !important;
}

.letter-space-xl {
  letter-spacing: 1.5px !important;
}

// **************** FONT WEIGHTS ****************

.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-extra-light {
  font-weight: 200 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}


// **************** TEXT OVERFLOWS ****************

.soft-wrap {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-lines: 2;
  width: 100%;
}

.fade-text {
  position: relative;
  height: 4.8em; /* exactly three lines */
  overflow: hidden;

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 1.6em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

